import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout.js";

// markup
const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <header className="master-header video-header">
        <iframe src={'https://player.vimeo.com/video/504203807?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" class="fade-in'} title="Vimeo Video"></iframe>
        <img  src={`/rings.svg`} alt="loader" className="loader" />
    </header>
    <section className="error-404 not-found">
        <div className="container main-content">
          <h2>Epic 404 - Article Not Found</h2>
          <p>This is embarassing. We can't find what you were looking for.</p>
          <p>Whatever you were looking for was not found, but maybe try looking again or search using the form below.</p>
        </div>
      </section>
  </Layout>
)

export default NotFoundPage
